import { mangeApi } from '@/plugins/mangeAxios'
import { app } from '@/plugins/appAxios'

// 发送短信验证码
export function getSmsCode (params) {
  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(params.phone_number)) {
    return Promise.reject({
      msg: '请输入正确的手机号码',
      code: '9000'
    })
  }
  return app('/send/sms/code/', params)
}

// 登录注册 app
export function loginOrRegister (params) {
  return app('/sms_code/login', params)
}

// 用户来源【注册成功后，调用， 渠道码：8n4Gyrax】 api
export function inviteRecord (params) {
  return mangeApi('/invite/user/record', params)
}

// 电子钱包信息 app
export function getAliveMerchant (params) {
  return app('/wallet/get_alive_merchant', params)
}

// 绑卡信息 app
export function merchantWithdrawCard (params) {
  return app('/wallet/merchant_withdraw_card', params)
}

// 用户红包基本信息 api
export function redpackageBase (params) {
  return mangeApi('/red_package/base', params)
}

// 参与记录 api
export function redPackageRecord (params) {
  return mangeApi('/red_package/record', params)
}

// 拆红包 api
export function redPackageOpen (params) {
  return mangeApi('/red_package/tear_open', params)
}

// 提现申请 api
export function withdrawApply (params) {
  return mangeApi('/red_package/withdraw_apply', params)
}

// 提现确认 api
export function withdrawConfirm (params) {
  return mangeApi('/red_package/withdraw_confirm', params)
}

/* 解绑微信 */
export function inviteBuild (params) {
  return app('/relations/build/relation', params)
}

// 分享app api
export function redPackageShareApp (params) {
  return mangeApi('/red_package/share_app', params)
}
